<template>
  <div id="invoice">
    <!-- view mode -->
    <!-- <div class v-if="!editMode && $router.currentRoute.params.id"> -->
    <div>
      <v-row>
        <v-col cols="12" md="4" sm="8" class="title-col col-xs-8">
          <div class="title">
            <h1 class="title">{{ $t("Create Message") }}</h1>
            <!-- <v-icon
              large
              class="show-xs"
              title="Go Back"
              @click="goToviewMode()"
              >close</v-icon
            > -->
          </div>
        </v-col>
        <v-col cols="12" md="4" sm="1" class="col-xs-1 hide-xs"> </v-col>
        <v-col cols="12" md="4" sm="3" class="text-right col-xs-3 hide-xs">
          <v-btn
            class="modal-btn-save"
            small
            @click="goToviewMode(true)"
            style="text-transform: none; margin: 5px 0 !important"
          >
            {{ $t("Save as draft") }}
          </v-btn>
          <v-icon large :title="$t('Go Back')" @click="goToviewMode()"
            >close</v-icon
          >
        </v-col>
      </v-row>

      <v-divider></v-divider>
      <v-row>
        <v-col cols="12" md="12"> </v-col>
      </v-row>

      <!-- <hr class="second" /> -->

      <v-row></v-row>
    </div>

    <div class="edit">
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col cols="12" md="12" class="info-list">
            <v-row>
              <v-col cols="12" md="6">
                <p class="value">
                  <strong class="custom-strong">{{ $t("To") }}:</strong>
                  <v-autocomplete
                    :disabled="disabled"
                    hide-selected
                    :items="allUsers"
                    @change="hideLabel = true && selectUsers()"
                    return-object
                    item-text="name"
                    item-value="id"
                    v-model="itemTo"
                    solo
                    chips
                  ></v-autocomplete>
                </p>
                <p class="value">
                  <strong class="custom-strong"
                    >{{ $t("Filter by Role") }}:</strong
                  >
                  <v-autocomplete
                    hide-selected
                    :disabled="disabledRoleSelect"
                    :items="allRoles"
                    @change="hideLabel = true"
                    item-text="name"
                    item-value="id"
                    v-model="selectedRole"
                    solo
                    chips
                  ></v-autocomplete>
                </p>

                <p class="value" v-if="showToClass">
                  <strong class="custom-strong">{{ $t("To Class") }}:</strong>
                  <v-autocomplete
                    :items="groups"
                    item-text="name"
                    item-value="id"
                    v-model.trim="item.class_ids"
                    :multiple="true"
                    solo
                  >
                  </v-autocomplete>
                </p>
                <p class="value" v-if="!parent && !student">
                  <v-checkbox
                    v-model="checkbox"
                    :label="$i18n.t('Student\'s parents')"
                  ></v-checkbox>
                  <strong v-if="checkbox" class="custom-strong"
                    >{{
                      $t(
                        "This option applies if you insert one student or more in the To field"
                      )
                    }}:</strong
                  >
                  <v-radio-group
                    v-if="checkbox"
                    v-model="sendToParent"
                    row
                    style="width: 100%"
                  >
                    <v-col cols="12" md="4">
                      <v-radio
                        :label="$i18n.t('Include the Parents')"
                        value="include_parents"
                      ></v-radio>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-radio
                        :label="$i18n.t('Send to Parents only')"
                        value="parents_only"
                      ></v-radio>
                    </v-col>
                  </v-radio-group>
                </p>

                <p class="value">
                  <strong class="custom-strong"
                    >{{ $t("Inbox Subject") }}:</strong
                  >
                  <v-text-field v-model="item.subject" solo></v-text-field>
                </p>

                <div v-if="validation_errors.subject" style="margin-top: -20px">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.subject"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <p class="value">
                  <!-- <strong class="custom-strong">{{ $t("To") }}:</strong>
                  <v-autocomplete multiple hide-selected :items="allUsers" @change="hideLabel = true" item-text="name" item-value="id"
                    v-model="item.recivers" solo chips></v-autocomplete> -->

                  <v-chip
                    class="ma-2"
                    v-for="(user, index) in chipsUsers"
                    :key="index"
                  >
                    {{ user.name }}
                    <v-icon
                      style="margin-left: 5px"
                      :title="$t('Click to delete')"
                      @click="removeItem(index)"
                      >close</v-icon
                    >
                  </v-chip>
                </p>
              </v-col>
            </v-row>
            <v-row>
              <!-- TO User -->
              <!-- <v-col cols="12" md="12">
                <p class="value">
                  <strong class="custom-strong">{{ $t("To") }}:</strong>
                  <v-autocomplete
                    multiple
                    hide-selected
                    :items="allUsers"
                    @change="hideLabel = true"
                    item-text="name"
                    item-value="id"
                    v-model="item.recivers"
                    solo
                    chips
                  ></v-autocomplete>
                </p>
              </v-col> -->
              <!-- To Class -->

              <!-- <v-col cols="12" sm="6" md="6" v-if="!parent && !student">
                <p class="value">
                  <strong class="custom-strong">{{ $t("To Class") }}:</strong>
                  <v-autocomplete
                    :items="groups"
                    item-text="name"
                    item-value="id"
                    v-model.trim="item.class_ids"
                    :multiple="true"
                    solo
                  ></v-autocomplete>
                </p>
              </v-col> -->
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <!-- <p class="value">
                  <strong class="custom-strong"
                    >{{ $t("Inbox Subject") }}:</strong
                  >
                  <v-text-field v-model="item.subject" solo></v-text-field>
                </p>

                <div v-if="validation_errors.subject" style="margin-top: -20px">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.subject"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div> -->
              </v-col>

              <v-col cols="12" md="12">
                <p class="value">
                  <strong class="custom-strong">{{ $t("Message") }}:</strong>

                  <vue-editor
                    v-model.trim="item.body"
                    :editorToolbar="customToolbar"
                  ></vue-editor>
                </p>
                <p class="red--text" v-if="validationEditor">
                  {{ $t("This field is required") }}
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-divider></v-divider>
        <v-row>
          <v-col cols="12" md="12">
            <div class="attach-files">
              <div class="filesname" v-if="fileNames != ''">
                <p class="files">{{ fileNames }}</p>
                <div class="clear">
                  <v-icon
                    title="Clear Attachments"
                    class=""
                    @click="clearAttach()"
                    >close</v-icon
                  >
                </div>
              </div>

              <!-- <input
                type="file"
                class="myFileBrowser"
                multiple
                ref="attachment"
                @change="readFiles"
                :rules="[validationRules.imageValidation]"
              /> -->
              <v-file-input
                class=""
                id="myFileBrowser"
                style="display: none !important"
                multiple
                ref="attachment"
                @change="readFiles"
              ></v-file-input>

              <v-btn icon @click="openFileInput()">
                <v-icon class="main-color" style="cursor: pointer"
                  >attach_file</v-icon
                >
              </v-btn>
              <v-icon
                v-if="this.$router.currentRoute.query.id"
                class="main-color"
                style="float: right; cursor: pointer"
                @click.prevent="updateDraft()"
                >send</v-icon
              >
              <v-icon
                v-else
                class="main-color"
                style="float: right; cursor: pointer"
                @click.prevent="save()"
                >send</v-icon
              >

              <p class="red--text" v-if="validationFiles">
                {{
                  $t("This File is Bigger Than Max File Size Allowed (50 MG)")
                }}
              </p>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </v-form>
    </div>
    <!-- snackbar -->
    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">{{ snackText }}</span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
    <!-- ovelay lodding -->
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import axios from "axios";
// import ACL from "../../acl";
import { validationMixin } from "../../mixins/validationMixin";
import { VueEditor } from "vue2-editor";
import ACL from "../../acl";
export default {
  name: "InboxCreate",
  mixins: [validationMixin],
  components: {
    VueEditor,
  },
  data() {
    return {
      options: {
        itemsPerPage: 100,
      },
      disabled: false,
      overlay: false,
      checkbox: false,
      parent: ACL.Role("Parent"),
      student: ACL.Role("Student"),
      showToClass:
        ACL.checkPermission("message-all-Class") &&
        !ACL.Role("Parent") &&
        !ACL.Role("Student"),

      //   attachment: [],
      fileNames: "",
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: "" }, { align: "justify" }, { align: "right" }],
        ["link"],
      ],
      itemTo: {},
      selectedRole: "",
      sendToParent: "defalut",
      chipsUsers: [],
      allRoles: [],
      allUsers: [],
      validationEditor: false,
      validationFiles: false,
      validationTo: false,
      snack: false,
      snackColor: "",
      snackText: "",
      snackTime: 3000,
      disabledRoleSelect: true,
      sizes: [],
      groups: [],
      item: {
        id: "",
        recivers: [],
        class_ids: [],
        body: "",
        subject: "",
        attachments: [],
      },

      valid: true,
      validation_errors: [],
    };
  },
  watch: {
    selectedRole: {
      handler() {
        this.getAllUsers();
        this.disabled = true;
      },
      deep: true,
    },
    checkbox: {
      handler() {
        if (this.checkbox == false) {
          this.sendToParent = "defalut";
        }
      },
      deep: true,
    },
    // allUsers:{
    //   handler() {
    //     if (this.item.recivers.length > 0){
    //       this.item.recivers.forEach(id => {
    //         var index = this.allUsers.indexOf(id);
    //         console.log('index => ' + index);
    //       })
    //     }else{
    //       console.log('0');
    //     }
    //   },
    //   deep: true,

    // },
  },
  methods: {
    selectUsers() {
      var found = this.chipsUsers.find((e) => e.id == this.itemTo.id);
      if (!found) {
        this.chipsUsers.push(this.itemTo);
        this.item.recivers.push(this.itemTo.id);
      }

      this.itemTo = {};
    },
    removeItem(index) {
      this.chipsUsers.splice(index, 1);
      this.item.recivers.splice(index, 1);
    },
    getAllRoles() {
      axios
        .get(this.getApiUrl + "/inbox/getAllRoles", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.allRoles = response.data.data;
        });
    },
    getAllUsers() {
      axios
        .get(
          this.getApiUrl + "/inbox/getAllUsers?role_id=" + this.selectedRole,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.allUsers = response.data.data;
          this.disabled = false;
          this.disabledRoleSelect = false;
        });
    },
    getMessage() {
      let id = this.$router.currentRoute.query.id;
      axios
        .get(this.getApiUrl + "/inbox/showDraftMessage/" + id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.item.id = response.data.data.id;
          this.item.recivers = response.data.data.recivers;
          this.item.body = response.data.data.body;
          this.item.subject = response.data.data.subject;
        });
    },
    openFileInput() {
      document.getElementById("myFileBrowser").click();
    },
    clearAttach() {
      this.item.attachments = [];
      this.fileNames = "";
      this.validationFiles = false;
      this.sizes = [];
    },
    save() {
      if (!this.valid) {
        this.validate();
      } else {
        if (this.item.body == "") {
          this.validationEditor = true;
        } else if (
          this.item.recivers.length == 0 &&
          this.item.class_ids.length == 0
        ) {
          // this.validationTo = true;
          this.snack = true;
          this.snackColor = "red";
          this.snackText = "can't send the message , please select recipient .";
        } else {
          let totalSize = 0;
          this.sizes.forEach((size) => {
            totalSize += size;
          });
          if (totalSize > 50) {
            this.validationFiles = true;

            return;
          }
          this.validationEditor = false;
          this.overlay = true;
          const formData = new FormData();
          if (this.item.attachments.length > 0) {
            this.item.attachments.forEach((file) => {
              formData.append("file[]", file);
            });
          }
          formData.append("sendToParent", this.sendToParent);
          formData.append("body", this.item.body);
          formData.append("subject", this.item.subject);
          if (this.item.recivers.length > 0 && this.item.recivers != "") {
            this.item.recivers.forEach((reciver) => {
              formData.append("recivers[]", reciver);
            });
          }

          if (this.item.class_ids.length > 0 && this.item.class_ids != "") {
            this.item.class_ids.forEach((reciver) => {
              formData.append("class_ids[]", reciver);
            });
          }

          axios
            .post(this.getApiUrl + "/inbox/store", formData, {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            })
            .then((response) => {
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.fileNames = "";

                this.$refs.form.resetValidation();
                this.overlay = false;
                this.$router.push(
                  {
                    path: "/" + "inbox",
                  },
                  () => {}
                );
              }
            });
        }
      }
    },
    updateDraft() {
      if (!this.valid) {
        this.validate();
      } else {
        if (this.item.body == "") {
          this.validationEditor = true;
        } else {
          let totalSize = 0;
          this.sizes.forEach((size) => {
            totalSize += size;
          });
          if (totalSize > 50) {
            this.validationFiles = true;

            return;
          }
          this.validationEditor = false;
          const formData = new FormData();
          if (this.item.attachments.length > 0) {
            this.item.attachments.forEach((file) => {
              formData.append("file[]", file);
            });
          }
          formData.append("id", this.item.id);
          formData.append("body", this.item.body);
          formData.append("subject", this.item.subject);
          if (this.item.recivers.length > 0 && this.item.recivers != "") {
            this.item.recivers.forEach((reciver) => {
              formData.append("recivers[]", reciver);
            });
          }

          axios
            .post(this.getApiUrl + "/inbox/updateDraft", formData, {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            })
            .then((response) => {
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.fileNames = "";

                this.$refs.form.resetValidation();
                this.$router.push(
                  {
                    path: "/" + "inbox",
                  },
                  () => {}
                );
              }
            });
        }
      }
    },
    readFiles(files) {
      // console.log(files);
      var _this = this;

      files.forEach((file) => {
        // if (file.size > 20000000) {
        //   _this.validationFiles = true;

        //   return;
        // } else {
        _this.validationFiles = false;
        let sizemg = file.size / 1000000;
        _this.sizes.push(sizemg);
        if (_this.fileNames.length == 0) {
          _this.fileNames = file.name;
        } else {
          _this.fileNames = _this.fileNames + ", " + file.name;
        }
        _this.item.attachments.push(file);
        // }
      });
    },
    onFocus() {
      this.validation_errors = [];
    },
    // getUsers() {
    //   axios
    //     .get(this.getApiUrl + "/inbox/getAllUser", {
    //       headers: {
    //         Authorization: "Bearer " + localStorage.token,
    //       },
    //     })
    //     .then((res) => {
    //       if (res.data.status.error == false) {
    //         this.allUsers = res.data.data;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    goToviewMode(save = false) {
      if (this.$router.currentRoute.query.id) {
        this.$router.push(
          {
            path: "/" + "inbox",
          },
          () => {}
        );
      } else if (save) {
        if (
          this.item.body != "" ||
          this.item.subject ||
          this.item.recivers.length > 0
        ) {
          const formData = new FormData();
          if (this.item.attachments.length > 0) {
            this.item.attachments.forEach((file) => {
              formData.append("file[]", file);
            });
          }

          formData.append("body", this.item.body);
          formData.append("subject", this.item.subject);
          if (this.item.recivers.length > 0 && this.item.recivers != "") {
            this.item.recivers.forEach((reciver) => {
              formData.append("recivers[]", reciver);
            });
          }

          axios
            .post(this.getApiUrl + "/inbox/saveDraft", formData, {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            })
            .then((response) => {
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.fileNames = "";

                this.$refs.form.resetValidation();
                this.$router.push(
                  {
                    path: "/" + "inbox",
                  },
                  () => {}
                );
              }
            });
        } else {
          this.$router.push(
            {
              path: "/" + "inbox",
            },
            () => {}
          );
        }
      } else {
        this.$router.push(
          {
            path: "/" + "inbox",
          },
          () => {}
        );
      }
    },
  },
  mounted() {
    // this.getUsers();
    this.getAllUsers();
    this.getAllRoles();
    // console.log(this.$router.currentRoute.query.id);
    if (this.$router.currentRoute.query.id) {
      this.getMessage();
    } else if (this.$route.query.to) {
      this.item.recivers.push(Number(this.$route.query.to));
    }
    axios
      .get(this.getApiUrl + "/discussionboard/getClasses", {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      })
      .then((response) => {
        this.groups = response.data.data;
      });

    // if (!this.$router.currentRoute.params.id) {
    //   this.editedItem.transaction_date = new Date(Date.now());
    // }
    // if (this.$route.query.editMode && !this.confirmed) {
    //   this.editMode = true;
    //   // this.getItems(this.editedItem.warehouse_id);
    // } else if (this.$route.query.editMode && this.confirmed) {
    //   this.editMode = false;
    //   this.$router.push(
    //     {
    //       path: "/" + "warehouses_transactions/show/" + this.id,
    //     },
    //     () => {}
    //   );
    // }
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
.myFileBrowser {
  opacity: 0;
  position: absolute;
  left: 0;
  z-index: 999;
}
hr.basic {
  margin: 0;
}
hr.second {
  border: 2px solid $main-color;
  margin: 0 auto;
  background-color: $main-color;
}

.title-col {
  padding-bottom: 0;
}

.custom-strong {
  display: block;
  margin-bottom: 6px;
}
.text-right {
  text-align: right !important;
}
.attach-files {
  padding: 0 10px;
}
.filesname {
  background: #eee;
  padding: 5px;
  border-radius: 13px;
  overflow: hidden;
}
.files {
  display: initial;
}
.filesname .clear {
  float: right;
}
.show-xs {
  display: none;
}

@media screen and (max-width: 420px) {
  .title {
    display: inline;
  }
  .show-xs {
    display: inline;
    float: right;
  }
  .hide-xs {
    display: none;
  }
}
</style>
